import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

const base = '/api/auth/'

const module = 'two-factor'

const path = base + module

const getTwoFactorQrCode = path + '/qrcode' // 獲取二維碼
const bindTwoFactor = path + '/bind' // 綁定二維碼
const unbindTwoFactor = path + '/unbind' // 取消綁定二維碼
const checkTwoFactor = path + '/check' // 檢查二維碼

/**
 * 產生二維碼
 * @param {Object} callback
 * @returns
 */
const getTwoFactorQrCodeFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getTwoFactorQrCode, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 檢查是否有綁定二次驗證
 * @param {Object} callback
 * @returns
 */
const checkTwoFactorFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + checkTwoFactor, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 綁定二維碼
 * @param {Object} formData
 * @param {Object} callback
 * @returns
 */
const bindTwoFactorFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + bindTwoFactor, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}
/**
 * 取消綁定二維碼
 * @param {Object} formData
 * @param {Object} callback
 * @returns
 */
const unbindTwoFactorFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + unbindTwoFactor, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

export {
  getTwoFactorQrCodeFn,
  bindTwoFactorFn,
  checkTwoFactorFn,
  unbindTwoFactorFn
}
