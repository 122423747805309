<template>
  <div id="blacklist" class="h-100">
    <!-- main content -->
    <v-card-text class="d-flex flex-column pa-0">
      <div class="font-weight-bold" style="font-size:17px;">
        <i class="fa-solid fa-circle-check sucColor" /> 綁定成功
      </div>
      <div class="mt-2">
        您已經啟用了二次驗證功能。請再次確認您的設備是否成功綁定，以確保您下次訪問時可以順利進行驗證。
        如果您需要在不同的設備上進行驗證，請先停用二次認證，然後重新綁定您的設備。
      </div>

      <div class="mt-6 mb-6">
        <CommonButtonAdd name=" 關閉二次驗證" @click="showDlg()" />
      </div>
    </v-card-text>
    <LazyProfileTwoFactorUnbindTwoFactorDlg :is-show="dlgVisible" @closeDlg="closeSensitiveDlg" />
  </div>
</template>

<script setup>
const { refreshQrCodeCheck } = inject('provideInfo')

const dlgVisible = ref(false)

// 顯示 add / edit Dlg
const showDlg = () => {
  dlgVisible.value = true
}

// 關閉 dlg
const closeSensitiveDlg = () => {
  dlgVisible.value = false
  refreshQrCodeCheck()
}
</script>
