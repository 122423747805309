<template>
  <div id="blacklist">
    <v-card-text class="d-flex flex-column pa-0">
      步驟一:
      <div>
        請使用Google Authenticator等令牌驗證器來掃描此QR碼
      </div>

      <div class="d-flex flex-md-1-0-0  align-center mt-4 mb-10">
        <div class="d-flex flex-column justify-xl-center align-center me-4">
          <img :src="qrInfo.qrCodeUrl" width="220">
        </div>
        <CommonCard>
          <div>
            <div class="font-weight-bold" style="font-size:16px;">
              無法識別?
            </div>
            <div>
              如果您需要手動設定金鑰,請向您的手機應用程式提供以下詳細資訊
            </div>
            <div style="height:50px" />
            <div>帳號 : <span class="font-weight-bold">請輸入自己帳號</span> </div>
            <div>金鑰 : <span class="font-weight-bold">{{ qrInfo.secret }}</span></div>
          </div>
        </CommonCard>
      </div>
      步驟二:
      <div>
        輸入手機上【PIN碼】後，要記得綁定二次驗證
      </div>

      <div class="mt-4 d-flex">
        <v-text-field
          v-model="twoFactorCode"
          type="text"
          density="compact"
          variant="plain"
          placeholder="請輸入PIN"
          class="plainInput me-4"
          style="flex: 0 0 300px;"
          clearable
          @keyup.enter="submitBind"
        />
        <CommonButtonAdd name=" 綁定二次驗證" :disabled="twoFactorCode==''" :loading="loading" @click="submitBind" />
      </div>
    </v-card-text>
  </div>
</template>

<script setup>
import {
  getTwoFactorQrCodeFn,
  bindTwoFactorFn
} from '../../../api/auth/twofactorAuth'

const { refreshQrCodeCheck } = inject('provideInfo')

onBeforeMount(() => {
  refreshQrcode()
})

const qrInfo = reactive({
  qrCodeUrl: '',
  secret: ''
})

const loading = ref(false)
const twoFactorCode = ref('')

const submitBind = () => {
  loading.value = true

  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      refreshQrCodeCheck()
    }
    loading.value = false
  }
  const formData = {
    code: twoFactorCode.value,
    secretKey: qrInfo.secret
  }
  bindTwoFactorFn(formData, onRes)
}

// 刷新敏感詞
const refreshQrcode = async () => {
  const onRes = ({ response }) => {
    const res = response._data

    if (res.code === 200) {
      qrInfo.qrCodeUrl = res.data.qrCode
      qrInfo.secret = res.data.secretKey
    }
  }

  await getTwoFactorQrCodeFn(onRes)
}

provide('provideInfo', {
  refreshQrcode
})
</script>

<style lang="scss" scoped>
  @import "assets/styles/pages/setup.scss";

  .plainInput { // 輸入框
    :deep(.v-field__input), :deep(.v-field__clearable) {
      padding-top: 4px;
    }
    :deep(.v-input__control) {
      border: 1px solid #c7c7c7;
      border-radius: 3px;
      padding: 5px 15px;
    }
  }
</style>
